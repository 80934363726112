<template>
    <el-container style="height: 101vh;">
        <div v-show="topShow" class="topLang" >
            <span  id="langCN" :class="lang=='CN'?'langClass':''" style="cursor: pointer;" @click="langageChange('CN')">中</span>｜<span id="langEN" :class="lang=='CN'?'':'langClass'" style="cursor: pointer;" @click="langageChange('EN')">EN</span>
        </div>
        <el-main>
            <div id="dowload_content">
                <!-- 头部 -->
                <div class="top_content"  style="    padding: 10%;">
                    <div class="top_title">
                        <span class="label_title">{{$t('label_title')}}</span>
                        <br/>
<!--                        <span class="label_subtitle">{{$t('label_subtitle')}}</span>-->
                    </div>
                </div>



                <!-- android -->
                <div style="margin: 6vw;">
                    <img class="android_img" :src="lang=='CN'?require('../assets/android_dowload.png'):require('../assets/android_dowload_en.png')"></img>
                    <div style="float: left;text-align: left;margin: 5vw;">
                        <span class="title">{{$t("label_android_title")}}</span>
                        <br/>
                        <span class="title_subtitle">{{$t("label_android_subtitle")}}</span>
                        <br/>
                        <span class="title_subtitle">{{$t("label_android_subtitle1")}}</span>
                        <br/>
                        <div style="padding-top: 5%;">
                            <el-button v-show="usb01Show" style="margin: 0.3em;" type="primary" @click="dowload('http://usbdisplay.cn/AppStore2/api/download?appId=111c6f8d-ff76-4172-968a-910deabe1935')" round>{{$t("label_new_dowload")}}</el-button>


                            <el-button v-show="quickShareShow" style="margin: 0.3em;" type="primary" @click="dowload('http://usbdisplay.cn/AppStore2/api/download?appId=23d837b7-8c47-4285-a9bd-eba617104684')" round>{{$t("label_dowload")}}</el-button>
                        </div>
                    </div>
                </div>


            </div>

        </el-main>
        <el-footer v-show="isFiling" class="footerClass">{{$t('label_footer')}}<a style="color: #ffffff;text-decoration: none;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{$t('label_footer1')}}</a></el-footer>
        <!-- 国际版的不需要备案信息 -->
        <el-footer v-show="!isFiling" class="footerClass">{{$t('label_footer')}}</el-footer>
    </el-container>

</template>

<script>
    //引入axios
    import axios from 'axios';
    import Configdialog from '@/components/Configdialog'
    import Message from '@/components/Message'
    import $ from 'jquery'
    export default {
        name: "Dowload",
        data(){
            return {
                //是否显示头部
                topShow: false,
                //国内版，需要提示备案信息;国际版，不需要提示备案信息
                isFiling: false,
                //www.usb01.com域名下载地址
                usb01Show: true,
                //quickshare产品域名下载地址
                quickShareShow: false,
                //点击事件样式
                dowloadAction:1,
                lang:localStorage.getItem('lang'),
                urlList:[
                    {url:require('../assets/window.png'),url_en:require('../assets/window_click.png'),name:'Windows'},
                    {url:require('../assets/macOS.png'),url_en:require('../assets/macOS_click.png'),name:'macOS'},
                    {url:require('../assets/IOS.png'),url_en:require('../assets/IOS_click.png'),name:'iOS&iPadOS'},
                    {url:require('../assets/Android.png'),url_en:require('../assets/android_click.png'),name:'Android'},
                    {url:require('../assets/Linux.png'),url_en:require('../assets/Linux_click.png'),name:'Linux'},
                ]
            }
        },
        mounted() {
            document.title = this.$t('title');
        },
        methods:{
            switchDowload(index){
                let col = $('.actionCol');
                let a =$(col[0]);
                this.dowloadAction = index+1;
                a.removeClass("actionCol");
                $("#switchDowload_"+index).addClass("actionCol");


                let img = this.urlList[a[0].querySelector("img").getAttribute("id")].url;
                a[0].querySelector("img").setAttribute('src',img)
                $("#switchDowload_"+index)[0].querySelector("img").setAttribute('src',this.urlList[index].url_en)
            },
            dowload(url){
                location.href =url;
            },
            langageChange(localLang){
                this.$i18n.locale = localLang;
                // 要保存语言类型
                window.localStorage.setItem('lang',localLang);
                this.lang = localStorage.getItem('lang');
                if(localLang ==='CN'){
                    $("#langEN").removeClass("langClass");
                    $("#langCN").addClass("langClass");
                }else{
                    $("#langCN").removeClass("langClass");
                    $("#langEN").addClass("langClass");
                }
                document.title = this.$t('title');
            }
        }
    }
</script>

<style>
    .langClass{
        color: #3470FF;
        border-bottom: 2px solid #3470FF;
    }
    .topLang{
        margin: 1vw;
        position: absolute;
        right: 1px;
        font-size: 1.5vw;
        font-weight: bold;
        color: #222222;
        opacity: 1;
    }
    body{
        background-image: url("../assets/modile_top.png");
        background-repeat: no-repeat;
        /*background-color: rgba(251,253,255,0.39);*/
        margin:0;
    }
    .label_title{
        font-size: 6vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #222222;
        opacity: 1;
    }
    .label_subtitle{
        font-size: 1.5vw;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4C4C4C;
        opacity: 1;
    }
    .title{
        font-size: 2vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }
    .title_subtitle{
        font-size: 1.5vw;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 1vh;
        color: #6D6D6D;
        opacity: 1;
    }


    .actionCol{
        color: #3470FF;
        border: 2px solid #3470FF;
        opacity: 1;
        border-radius: 22px;
    }
    .el-divider--horizontal{
        width: 96%!important;
        margin: 1vw 3vw 1vw 3vw!important;
    }

    .el-image .el-image__inner{
        width: 1.2em;
        height: 1.2em;
    }
    .demonstration{
        font-size: 2vw;
    }
    .el-card__body, .el-main{
        padding: 1.5vw;
    }
    .footerClass{
        color: #ffffff;
        background-color: #1A1A1A;
        font-size: 2.5vw;
        padding: 1.8vh;
    }
    .windows_img{
        width: 80%;
        height: 80%;
    }
    .macOS_img{
        width: 80%;
        height: 80%;
    }
    .ipadOs_img{
        width: 70%;
        height: 70%;
    }
    .android_img{
        width: 40%;
        height: 40%;
    }
    .linux_img{
        width: 80%;
        height: 80%;
    }

    @media screen and (orientation: landscape){
        .footerClass{
            font-size: 2vw;
        }
        .label_title{
            font-size: 2vw;
        }
        .windows_img{
            width: 40%;
            height: 40%;
        }
        .macOS_img{
            width: 40%;
            height: 40%;
        }
        .ipadOs_img{
            width: 30%;
            height: 30%;
        }
        .android_img{
            width: 25%;
            height: 25%;
        }
        .linux_img{
            width: 40%;
            height: 40%;
        }
    }
</style>